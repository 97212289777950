import Cookies from 'js-cookie';

/**
 * Sets the default authorization
 * @param {*} token
 */

class APIClient {
	/**
	 * Fetches data from given url
	 */
	get = (url, token) => {
		let Access_token = token ? token : Cookies.get('access_token');
		var myHeaders = new Headers(); // Creating Header
		myHeaders.append('Content-Type', 'application/json');
		// cors
		// myHeaders.append('Access-Control-Allow-Origin', '*');
		// myHeaders.append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE");
		// myHeaders.append('Access-Control-Allow-Headers', 'Content-Type');
		// myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Authorization', `Bearer ${Access_token}`);
		myHeaders.append('X-Requested-With', `${process.env.REACT_APP_API_URL}.${process.env.REACT_APP_MAIN_NAME}.chat`);

		var requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${Access_token}`,
			},
		};

		let obj = fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions)
			.then((response) => {
				return response.text();
			})
			.then((result) => {
				return result;
			});
		return obj;
	};

	/**
	 * post given data to url
	 */
	create = (url, data) => {
		// var myHeaders = new Headers(); // Creating Header
		// myHeaders.append('Content-Type', 'application/json');
		// // cors
		// myHeaders.append('Access-Control-Allow-Origin', '*');
		// // myHeaders.append('Access-Control-Allow-Methods', 'POST');
		// // myHeaders.append('Access-Control-Allow-Headers', 'Content-Type');
		// myHeaders.append('Access-Control-Allow-Credentials', 'true');

		var raw = JSON.stringify(data);

		let obj = fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				'Access-Control-Allow-Origin': '*',
			},
			body: raw,
			redirect: 'follow',
		})
			.then((response) => {
				return response.text();
			})
			.then((result) => {
				return result;
			})
			.catch((error) => {
				console.log('error', error);
			});

		return obj;
	};
}

export { APIClient };
