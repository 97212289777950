import {
	CHAT_USER,
	ACTIVE_USER,
	FULL_USER,
	ADD_LOGGED_USER,
	CREATE_GROUP,
	ONLINE_USERS,
	LAST_MESSAGES,
	ACTIVE_CHAT,
	CHAT_LIST,
	ADD_CHAT_LIST,
	UPDATE_CHAT_LIST,
	ACTIVE_CHAT_ID,
	DELETE_CHAT,
	CHANGE_LAST_MESAGE,
	UPDATE_SINGLE_MESSAGE,
	CONNECTION_DATA,
	OLD_MESSAGE_LOADİNG,
	OLD_MESSAGE_LOADİNG_SUCCESS,
	OLD_MESSAGE_LOADİNG_ERROR,
	NEW_MESSAGE,
	NEW_MESSAGE_NEW_CHAT,
	UPDATE_NEWCHATS,
	OPEN_WITH_CHATROOM,
	OPEN_WITH_DM,
	UPDATE_PAGE,
	LAST_MESSAGE_ALERT,
} from './constants';

//Import Images
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import avatar4 from '../../assets/images/users/avatar-4.jpg';
import avatar3 from '../../assets/images/users/avatar-3.jpg';
import avatar6 from '../../assets/images/users/avatar-6.jpg';
import avatar7 from '../../assets/images/users/avatar-7.jpg';
import avatar8 from '../../assets/images/users/avatar-8.jpg';
import img6 from '../../assets/images/small/img-6.jpg';
import img4 from '../../assets/images/small/img-4.jpg';
import img7 from '../../assets/images/small/img-7.jpg';
import { NavItem } from 'reactstrap';

const INIT_STATE = {
	active_user: 0,
	users: [],

	chatList: [],
	online_user_list: [],
	lasmessages: [],
	activeChat: null,
	activeChatId: null,
	connectionData: null,
	oldMessageLoading: false,
	oldMessageError: null,
	openWithChatroom: null,
	openWithDM: null,
	alert: null,
};

const Chat = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHAT_USER:
			return { ...state };

		case ACTIVE_CHAT:
			return {
				...state,
				activeChat: action.payload,
			};

		case ACTIVE_USER:
			return {
				...state,
				active_user: action.payload,
			};

		case FULL_USER:
			return {
				...state,
				users: action.payload,
			};

		case ADD_LOGGED_USER:
			const newUser = action.payload;
			// check is user alredy in list
			const isUserExist = state.users.find((user) => user.chat_id === newUser.chat_id);
			if (isUserExist) {
				return { ...state };
			} else {
				return {
					...state,
					users: [...state.users, newUser],
				};
			}
		case ONLINE_USERS:
			return {
				...state,
				online_user_list: action.payload,
			};

		case CREATE_GROUP:
			const newGroup = action.payload;
			return {
				...state,
				groups: [...state.groups, newGroup],
			};
		case LAST_MESSAGES:
			return {
				...state,
				lasmessages: action.payload,
			};
		case CHAT_LIST:
			return {
				...state,
				chatList: action.payload,
			};
		case ADD_CHAT_LIST:
			const newChat = action.payload;
			return {
				...state,
				chatList: [...state.chatList, newChat],
			};

		case UPDATE_CHAT_LIST:
			const updateChat = action.payload;
			let copyCopy = state.chatList;
			let chatListcopy = [...copyCopy];
			chatListcopy.map((chat, index) => {
				if (updateChat.chat_id == chat.chat_id) {
					if (chat.messages == undefined && chat.chatroom == undefined) {
						chat.messages = [];
						chat.messages.push(updateChat.message);
					} else if (chat.messages == undefined && chat.chatroom) {
						let newMessage = [updateChat.message];

						let copy = chatListcopy[index].data;

						chatListcopy[index].data = copy.concat(newMessage);
					} else {
						chatListcopy[index].messages.data.push(updateChat.message);
					}
				}
			});
			return { ...state, chatList: chatListcopy };

		case ACTIVE_CHAT_ID:
			return {
				...state,
				activeChatId: action.payload,
			};
		case DELETE_CHAT:
			const deleteChat = action.payload;
			let chatListcopy2 = [...state.chatList];
			chatListcopy2.map((chat) => {
				if (deleteChat == chat.chat_id) {
					chatListcopy2.splice(chatListcopy2.indexOf(chat), 1);
				}
			});
			return { ...state, chatList: chatListcopy2 };
		case CHANGE_LAST_MESAGE:
			const changeLastMessage = action.payload;
			let chatListcopy3 = [...state.chatList];
			chatListcopy3.map((chat) => {
				if (changeLastMessage.chat_id == chat.chat_id) {
					chat.last_message = changeLastMessage.message;
				}
			});
			return { ...state, chatList: chatListcopy3 };

		case UPDATE_NEWCHATS:
			const updateNewChats = action.payload;
			let chatListcopy99 = [...state.chatList];

			chatListcopy99.map((chat) => {
				if (updateNewChats[0].chat_room_chat_id == chat.chat_id || updateNewChats[0].chat_id == chat.chat_id) {
					// add updateNewChat to chat
					if (chat.chatroom == undefined) {
						chat.messages.data = [...chat.messages.data, ...updateNewChats];
					} else if (chat.chatroom) {
						chat.data = [...updateNewChats, ...chat.data];
						// chat.data.concat(updateNewChats);
					}
				}
			});
			return { ...state, chatList: chatListcopy99 };

		case UPDATE_SINGLE_MESSAGE:
			const updateSingleMessage = action.payload;
			let chatListcopy4 = [...state.chatList];
			let chat_id = updateSingleMessage.chat_id;
			let message = updateSingleMessage.message;
			let id = updateSingleMessage.message_id;
			let to_id = updateSingleMessage.to_id;

			chatListcopy4.map((chat) => {
				if (updateSingleMessage.chatroom == undefined) {
					if (chat_id == chat.chat_id) {
						chat.last_message.id = id;
						chat.last_message.message = message;
						chat.last_message.is_sendToOthers = 1;
						chat.messages.data.map((i, index) => {
							if (i.id == 'temp' && i.message == message) {
								chat.messages.data[index].is_sendToOthers = 1;
								chat.messages.data[index].id = id;
							}
						});
					} else if (chat.chat_id == to_id && chat.last_message.message == message) {
						chat.last_message.id = id;
						chat.last_message.message = message;
						chat.last_message.is_sendToOthers = 1;
						chat.messages.data.map((i, index) => {
							if (i.id == 'temp' && i.message == message) {
								chat.messages.data[index].is_sendToOthers = 1;
								chat.messages.data[index].id = id;
							}
						});
					}
				} else if (chat_id == chat.chat_id && updateSingleMessage.chatroom) {
					chat.data.map((i, index) => {
						if (i.id == 'temp' && i.message == message) {
							chat.data[index].is_sendToOthers = 1;
							chat.data[index].id = id;
						}
					});
				}
			});
			let newUpdatedList = [...chatListcopy4];
			return { ...state, chatList: newUpdatedList };
		case CONNECTION_DATA:
			return {
				...state,
				connectionData: action.payload,
			};
		case OLD_MESSAGE_LOADİNG:
			return {
				...state,
				oldMessageLoading: true,
			};
		case OLD_MESSAGE_LOADİNG_SUCCESS:
			return {
				...state,
				oldMessageLoading: false,
			};
		case OLD_MESSAGE_LOADİNG_ERROR:
			return {
				...state,
				oldMessageLoading: false,
				oldMessageError: action.payload,
			};

		case NEW_MESSAGE:
			const newMessage = action.payload;
			let chatListcopy5 = [...state.chatList];
			chatListcopy5.map((chat, index) => {
				if (newMessage.chat_id == chat.chat_id && newMessage.chatroom == undefined) {
					chatListcopy5[index].messages.data.push(newMessage);
					chatListcopy5[index].last_message = newMessage;
				} else if (newMessage.chat_id == chat.chat_id && newMessage.chatroom) {
					let newMessageArray = [newMessage];

					let copy = chatListcopy5[index].data;

					chatListcopy5[index].data = copy.concat(newMessageArray);
				}
			});
			return { ...state, chatList: chatListcopy5 };
		case NEW_MESSAGE_NEW_CHAT:
			const newMessageNewChat = action.payload;
			let chatListcopy6 = [...state.chatList];
			chatListcopy6.map((i, index) => {
				if (i.chat_id == newMessageNewChat.result.from_id && i.away_user.user_id == newMessageNewChat.result.from_id) {
					chatListcopy6[index].chat_id = newMessageNewChat.result.chat_id;
					let last = {
						id: newMessageNewChat.result.id,
						message: newMessageNewChat.result.message,
						is_sendToOthers: 1,
						from_id: newMessageNewChat.result.from_id,
						to_id: newMessageNewChat.result.to_id,
						chat_id: newMessageNewChat.result.chat_id,
						chatroom: newMessageNewChat.result.chatroom,
						is_channel: 0,
					};
					let message = {
						chat_id: newMessageNewChat.result.chat_id,
						created_at: Date.now(),
						from_id: newMessageNewChat.result.from_id,
						message: newMessageNewChat.result.message,
						message_id: newMessageNewChat.result.id,
						is_channel: '0',
						is_seen: '0',
						to_id: newMessageNewChat.result.to_id,
						updated_at: Date.now(),
					};

					chatListcopy6[index].last_message = last;
					chatListcopy6[index].messages.data.push(message);
					chatListcopy6[index].away_user = newMessageNewChat.result.from_user;
				}
			});
			return { ...state, chatList: chatListcopy6, activeChatId: newMessageNewChat.result.chat_id };
		case OPEN_WITH_CHATROOM:
			return { ...state, openWithChatroom: action.payload };
		case OPEN_WITH_DM:
			return { ...state, openWithDM: action.payload };
		case UPDATE_PAGE:
			let copyForPage = [...state.chatList];
			let activeChat = state.activeChatId;
			//find active chat at copyforpage
			let activeChatIndex = copyForPage.findIndex((i) => i.chat_id == activeChat);
			let activeChatData = copyForPage[activeChatIndex];
			activeChatData.page = activeChatData.page + 1;
			//find active chat at chatlist
			return { ...state, chatList: copyForPage };
		case LAST_MESSAGE_ALERT:
			return { ...state, alert: action.payload };

		default:
			return { ...state };
	}
};

export default Chat;
