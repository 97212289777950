import React, { useEffect } from 'react';
import Routes from './routes';
import { t } from 'i18next';

//Import Scss
import './assets/scss/themes.scss';

function App() {
	useEffect(() => {
		let title = process.env.REACT_APP_MAIN_NAME;
		// delete .com from title and capitalize first letter
		title = title.slice(0, -4).charAt(0).toUpperCase() + title.slice(0, -4).slice(1);
		title = `${t('Chat')} | ${title} `;
		document.title = title || 'Chat App';
	}, []);
	return <Routes />;
}

export default App;
