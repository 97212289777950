import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, UncontrolledTooltip, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import classnames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';

import { setActiveTab, changeLayoutMode } from '../redux/actions';

//Import Images
import logo from '../assets/images/logo.png';

//i18n
import i18n from '../i18n';

// falgs
import usFlag from '../assets/images/flags/us.jpg';

import french from '../assets/images/flags/french.jpg';

import Cookies from 'js-cookie';
import { t } from 'i18next';

function LeftSidebarMenuNonAuth(props) {
	const dispatch = useDispatch();
	const { layoutMode } = useSelector((state) => ({
		layoutMode: state.Layout.layoutMode,
	}));

	// const modeLayout = localStorage.getItem('layoutMode');

	const mode = layoutMode === 'dark' ? 'light' : 'dark';

	const onChangeLayoutMode = (value) => {
		if (changeLayoutMode) {
			dispatch(changeLayoutMode(value));
		}
	};

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [dropdownOpen2, setDropdownOpen2] = useState(false);
	const [dropdownOpen3, setDropdownOpen3] = useState(false);
	const [loading, setLoading] = useState(true);

	const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);

	const [lng, setlng] = useState();
	// get the language from local storage

	useEffect(() => {
		Cookies.get('lang_code') == 'fr' ? setActiveLanguage('fr') : setActiveLanguage('eng');
	}, []);

	const setActiveLanguage = (lng) => {
		lng == 'fr' && setlng('French');
		lng == 'eng' && setlng('English');
	};

	const toggle = () => setDropdownOpen(!dropdownOpen);
	const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
	const toggle3 = () => setDropdownOpen3(!dropdownOpen3);

	const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

	//token
	const token = Cookies.get('access_token');
	let isUserLoggedIn = props.user == null ? false : true;

	useEffect(() => {
		if (isUserLoggedIn) {
			setLoading(false);
		} else if (!token) {
			setLoading(false);
		}
	}, [token, isUserLoggedIn]);

	const toggleTab = (tab) => {
		props.setActiveTab(tab);
	};

	let activeTab = props.activeTab;

	/* changes language according to clicked language menu item */
	const changeLanguageAction = (lng) => {
		/* set the selected language to i18n */
		i18n.changeLanguage(lng);

		if (lng === 'eng') setlng('English');
		else if (lng === 'fr') setlng('French');
	};
	const queryParameters = window.location.pathname;
	var path = queryParameters.slice(1);

	if (loading) {
		return (
			<div className="d-flex justify-content-center align-items-center  px-2 side-menu">
				<div className="spinner-border" role="status">
					<span className="sr-only"></span>
				</div>
			</div>
		);
	} else {
		return (
			<React.Fragment>
				<div className="side-menu flex-lg-column me-lg-1 stickyP ">
					{/* LOGO */}
					<div className="navbar-brand-box ">
						<a className="pointer" href={`${isUserLoggedIn ? process.env.REACT_APP_MAIN_TRANSFER : process.env.REACT_APP_MAIN_DOMAIN}`} target="_blank">
							<div>
								<div>
									<div className="logo logo-dark">
										<span className="logo-sm">
											<img src={logo} alt="logo" height="30" />
										</span>
									</div>

									<div className="logo logo-light">
										<span className="logo-sm">
											<img src={logo} alt="logo" height="30" />
										</span>
									</div>
								</div>
								<div className="font-size-10 text-muted">
									<i className="ri-external-link-line font-size-24"></i>
									<br />

									<p className="px-1">{t('Go to website')}</p>
								</div>
							</div>
						</a>
					</div>
					{/* end navbar-brand-box  */}

					{/* Start side-menu nav */}
					<div className="flex-lg-column my-auto">
						<Nav pills className="side-menu-nav justify-content-center" role="tablist">
							{!isUserLoggedIn && (
								<div className="d-none d-lg-flex flex-lg-column align-items-center justify-content-center gap-2">
									{path !== 'login' && (
										<li className="nav-item  d-flex justify-content-center align-items-center">
											<a className=" d-flex flex-row flex-lg-column justify-content-center align-items-center gap-2" href="/login">
												<i className={`ri-logout-circle${!isUserLoggedIn ? '-r' : ''}-line  text-muted order-2 order-lg-1 d-none d-lg-block font-size-24`}></i>
												<p className={`order-1 order-lg-2 ${mode == 'dark' ? 'text-black' : 'text-white'}`} style={{ fontSize: '.75rem', marginBottom: '0' }}>
													{isUserLoggedIn ? t('Logout') : t('Login')}
												</p>
											</a>
										</li>
									)}
									{path !== 'register' && (
										<li className="nav-item  d-flex justify-content-center align-items-center">
											<a className=" d-flex flex-row flex-lg-column justify-content-center align-items-center gap-2" href="/register">
												<i className={`ri-folder-add-line text-muted order-2 order-lg-1 d-none d-lg-block font-size-24`}></i>
												<p className={`order-1 order-lg-2 ${mode == 'dark' ? 'text-black' : 'text-white'} `} style={{ fontSize: '.75rem', marginBottom: '0' }}>
													{t('Register')}
												</p>
											</a>
										</li>
									)}
									{path !== 'forget-password' && (
										<li className="nav-item  d-flex justify-content-center align-items-center">
											<a className=" d-flex flex-row flex-lg-column justify-content-center align-items-center gap-2" href="/forget-password">
												<i className={`ri-refresh-line text-muted order-2 order-lg-1 d-none d-lg-block  font-size-24`}></i>
												<p className={`order-1 order-lg-2 text-center ${mode == 'dark' ? 'text-black' : 'text-white'}`} style={{ fontSize: '.75rem', marginBottom: '0' }}>
													{t('Reset Password')}
												</p>
											</a>
										</li>
									)}
								</div>
							)}
							<li className="nav-item  d-flex justify-content-center align-items-center">
								<a className=" d-flex flex-row flex-lg-column justify-content-center align-items-center gap-2" href="/">
									<i className={`ri-home-line text-muted order-2 order-lg-1 d-none d-lg-block font-size-24`}></i>
									<p className={`order-1 order-lg-2 text-center ${mode == 'dark' ? 'text-black' : 'text-white'}`} style={{ fontSize: '.75rem', marginBottom: '0' }}>
										{t('Dashboard')}
									</p>
								</a>
							</li>

							<NavItem id="dashboard" className="d-lg-none">
								<NavLink id="backtodash" href="/dashboard">
									<i className="ri-home-line"></i>
								</NavLink>
								<UncontrolledTooltip target="backtodash" placement="bottom-start">
									{t('You can read chatrooms as a visitor.')}
								</UncontrolledTooltip>
							</NavItem>

							<Dropdown nav isOpen={dropdownOpen3} className="btn-group dropup profile-user-dropdown nav-item d-lg-none" toggle={toggle3}>
								<DropdownToggle nav>
									<i className="ri-global-line"></i>
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-translate-lang">
									<DropdownItem onClick={() => changeLanguageAction('eng')} active={lng === 'English'}>
										<img src={usFlag} alt="user" className="me-1" height="12" /> <span className="align-middle">English</span>
									</DropdownItem>

									<DropdownItem onClick={() => changeLanguageAction('fr')} active={lng === 'French'}>
										<img src={french} alt="user" className="me-1" height="12" /> <span className="align-middle">French</span>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>

							<>
								<Dropdown nav isOpen={dropdownOpen} direction="left" className="nav-item btn-group dropup end profile-user-dropdown d-lg-none" toggle={toggle}>
									<DropdownToggle className="nav-link" tag="a">
										<i className="ri-align-justify"></i>
									</DropdownToggle>
									<DropdownMenu right={true} className="dropdown-menu-translate-set">
										<DropdownItem id="light-dark" onClick={() => onChangeLayoutMode(mode)} className="d-flex align-items-center sp">
											{mode == 'light' ? t('Light Mode') : t('Dark Mode')}

											<i className="ri-sun-line theme-mode-icon float-end text-muted"></i>
										</DropdownItem>

										{/* <DropdownItem divider /> */}

										{/* <DropdownItem
											onClick={() => {
												toggleTab('settings');
											}}>
											Setting
											<i className="ri-settings-3-line float-end text-muted"></i>
										</DropdownItem> */}
										<DropdownItem divider />
										{path !== 'login ' && (
											<DropdownItem href="/login" className="d-flex align-items-center sp">
												{t('Login')}
												<i className="ri-logout-circle-r-line float-end text-muted"></i>
											</DropdownItem>
										)}
										<DropdownItem divider />
										{path !== 'register ' && (
											<DropdownItem href="/login" className="d-flex align-items-center sp">
												{t('Register')}
												<i className="ri-folder-add-line float-end text-muted"></i>
											</DropdownItem>
										)}
										<DropdownItem divider />
										{path !== 'forget-password ' && (
											<DropdownItem href="/forget-password" className="d-flex align-items-center sp">
												{t('Reset Password')}
												<i className="ri-refresh-line float-end text-muted"></i>
											</DropdownItem>
										)}
									</DropdownMenu>
								</Dropdown>
							</>
						</Nav>
					</div>
					{/* end side-menu nav */}

					<div className="flex-lg-column d-none d-lg-block">
						<Nav className="side-menu-nav justify-content-center align-items-center">
							<div className="d-lg-flex flex-column align-items-center justfiy-content-center d-none">
								<Dropdown nav isOpen={dropdownOpen2} className="btn-group dropup  profile-user-dropdown" toggle={toggle2}>
									<DropdownToggle nav>
										<i className="ri-global-line"></i>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={() => changeLanguageAction('eng')} active={lng === 'English'}>
											<img src={usFlag} alt="user" className="me-1" height="12" /> <span className="align-middle">English</span>
										</DropdownItem>

										<DropdownItem onClick={() => changeLanguageAction('fr')} active={lng === 'French'}>
											<img src={french} alt="user" className="me-1" height="12" /> <span className="align-middle">French</span>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<li className="nav-item">
									<NavLink id="light-dark" onClick={() => onChangeLayoutMode(mode)}>
										<i className="ri-sun-line theme-mode-icon"></i>
									</NavLink>
									<UncontrolledTooltip target="light-dark" placement="right">
										{t('Dark / Light Mode')}
									</UncontrolledTooltip>
								</li>

								{isUserLoggedIn && (
									<li className="nav-item">
										<a className=" d-flex flex-row flex-lg-column justify-content-center align-items-center gap-2" href="/logout">
											<i style={{ fontSize: '1.5rem', marginBottom: '0' }} className={`ri-logout-circle-line text-muted order-2 order-lg-1`}></i>
											<p className={`order-1 order-lg-1 text-center mb-2 ${mode == 'dark' ? 'text-black' : 'text-white'}`} style={{ fontSize: '.75rem', marginBottom: '0' }}>
												{t('Logout')}
											</p>
										</a>
									</li>
								)}
							</div>
						</Nav>
					</div>
					{/* Side menu user */}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const { user } = state.Auth;
	const { loading } = state.Auth;
	const { activeTab } = state.Layout;

	const { layoutMode } = state.Layout.layoutMode;
	return { user, layoutMode, loading, activeTab };
};

export default withRouter(
	connect(mapStateToProps, {
		setActiveTab,
	})(LeftSidebarMenuNonAuth),
);
