export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const IS_LOGINING = "IS_LOGINING"

export const LOGIN_USER_TWOFACTOR = "LOGIN_USER_TWOFACTOR"
export const LOGIN_USER_TWOFACTOR_SUCCESS = "LOGIN_USER_TWOFACTOR_SUCCESS"

export const GET_USER = "GET_USER"


export const LOGOUT_USER = "LOGOUT_USER";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const API_FAILED = "AUTH_API_FAILED";

export const STOP_LOADING = "STOP_LOADING"
